export default [
  {
    name: "Главная",
    path: "/",
    icon: "mdi-home",
    group: "",
    subItems: [],
  },

  {
    name: "Меню",
    path: "page",
    icon: "mdi-history",
    group: "pages",
    subItems: [
      {
        name: "Раздел 1",
        path: "path1",
        icon: "",
      },
    ],
  },
];
