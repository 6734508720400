<template>
  <v-slide-y-transition>
    <v-snackbar v-model="item.show" :color="item.color" :timeout="item.timeout" :style="`padding-bottom: ${index * 60 + 8}px`">
      {{ item.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="item.show = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-slide-y-transition>
</template>

<script>
export default {
  name: "Snackbar",

  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },

  watch: {
    "item.show": function() {
      this.$emit("remove", true);
    },
  },
};
</script>
