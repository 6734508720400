<template>
  <v-list dense>
    <template v-for="(item, idx) in menu">
      <v-list-item v-if="!item.subItems.length" :key="idx" link :to="item.path">
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group v-else :key="idx" :prepend-icon="item.icon" :group="item.group" :value="false">
        <template v-slot:activator>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </template>

        <template v-for="(subItem, subIdx) in item.subItems">
          <v-list-item v-if="item.name && item.path" :key="subIdx" link :to="subItem.path">
            <v-list-item-content>
              <v-list-item-title>{{ subItem.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-group>
    </template>
  </v-list>
</template>

<script>
import menu from "@/configs/menu";

export default {
  name: "MainMenu",

  data: () => ({
    menu,
  }),
};
</script>

<style scoped></style>
