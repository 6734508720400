<template>
  <div>
    <snackbar v-for="(item, idx) in notifications" :key="item.id" :index="idx" :item="item" @remove="onRemove" />
  </div>
</template>

<script>
import Snackbar from "@/plugins/notifications/Snackbar.vue";

export default {
  name: "Snackbars",

  components: { Snackbar },

  data: () => ({
    notifications: [],
  }),

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "notification/SHOW_MESSAGE") {
        this.notifications.push({ ...state.notification });
      }
    });
  },

  methods: {
    onRemove() {
      this.notifications = this.notifications.filter(item => item.show);
    },
  },
};
</script>
