<template>
  <v-card class="pa-0 toolbar-avatar" flat>
    <v-skeleton-loader v-if="!user" class="pr-0" type="avatar" />

    <v-list-item v-else class="pr-0">
      <v-list-item-content class="text-right">
        <v-list-item-title class="text-h5"> {{ user.surname }} {{ user.name }} {{ user.patronymic }} </v-list-item-title>

        <v-list-item-subtitle>
          <span v-if="user.phone">, {{ user.phone }}</span>
          <span v-if="user.email">, {{ user.email }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar color="grey">
        <img src="@/assets/img/avatar5.png" class="med-card__avatar" />
      </v-list-item-avatar>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "ToolbarAvatar",

  props: {
    positionRight: {
      type: Boolean,
    },
  },

  computed: {
    user() {
      return this.$store.state.auth.profile;
    },
  },
};
</script>

<style scoped lang="scss">
.toolbar-avatar {
  background: transparent !important;
}
</style>
